<!--
* @description:
* @fileName activeEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <div class="contentBox">
    <el-card>
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="formRef">
          <el-form-item label="活动标题" prop="title" :label-width="80">
            <el-input v-model="form.title" autocomplete="off" placeholder="请输入活动标题"></el-input>
          </el-form-item>

          <el-form-item label="排序值" prop="title" :label-width="80">
            <el-input-number v-model="form.sort" :min="1" label="请选择排序值"></el-input-number>
          </el-form-item>

          <el-form-item label="活动时间" prop="activeTime" :label-width="80">
            <el-date-picker v-model="form.activeTime" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
          </el-form-item>
          <!-- 
        <el-form-item label="结束时间" prop="endTime" :label-width="80">
          <el-date-picker
            v-model="form.endTime"
            type="datetime"
            placeholder="结束时间"
            value-format="YYYY-MM-DD HH:mm:ss"
          ></el-date-picker>
        </el-form-item> -->
          <el-form-item label="省市选择" prop="areaList" :label-width="80">
            <el-cascader :props="optionsLevel" v-model="form.areaList" v-if="showCasc" style="width: 250px" />
          </el-form-item>
          <el-form-item label="活动地址" prop="address" :label-width="80">
            <el-input v-model="form.address" autocomplete="off" placeholder="请输入活动地址"></el-input>
          </el-form-item>

          <el-form-item label="封面图片" prop="coverUrl" :label-width="80">
            <el-upload class="upload-demo" action="#" list-type="picture-card" :limit="1" :file-list="form.coverList" :http-request="upCover" :on-error="handleError1" :on-exceed="handleExceed1" :on-preview="handlePreview1" :on-remove="handleRemove1" accept=".png,.jpeg,.jpg">
              <el-icon class="el-icon--upload"><upload-filled /></el-icon>
              <div class="el-upload__text">
                <em>点击上传文件</em>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="海报图片" prop="bannerUrl" :label-width="80">
            <el-upload class="upload-demo" action="#" list-type="picture-card" :limit="1" :file-list="form.fileList" :http-request="upBannar" :on-error="handleError" :on-exceed="handleExceed" :on-preview="handlePreview" :on-remove="handleRemove" accept=".png,.jpeg,.jpg">
              <el-icon class="el-icon--upload"><upload-filled /></el-icon>
              <div class="el-upload__text">
                <em>点击上传文件</em>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="直播地址" prop="liveUrl" :label-width="80">
            <el-input v-model="form.liveUrl" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="主办方" prop="organisers" :label-width="80">
            <el-input v-model="form.organisers" autocomplete="off" placeholder="请输入主办方名称"></el-input>
          </el-form-item>

          <el-form-item label="活动人数" prop="peopleNum" :label-width="80">
            <el-input-number v-model="form.peopleNum" placeholder="请输入活动人数" :min="1" :max="99999" style="width: 200px" />
          </el-form-item>

          <el-form-item label="活动亮点" prop="sparkle" :label-width="80">
            <el-input type="textarea" v-model="form.sparkle" autocomplete="off" placeholder="请输入活动亮点"></el-input>
          </el-form-item>

          <el-form-item label="活动标签" :label-width="80" prop="activityTagIds">
            <el-select v-model="form.activityTagIds" placeholder="请选择活动标签" multiple>
              <template v-for="(item, index) in tagOption" :key="index">
                <el-option :label="item.label" :value="item.data"></el-option>
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="活动类型" :label-width="80" prop="activityTypeCodes">
            <el-select v-model="form.activityTypeCodes" multiple placeholder="请选择活动类型">
              <template v-for="(item, index) in typeOption" :key="index">
                <el-option :label="item.label" :value="item.data"></el-option>
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="活动状态" :label-width="80" prop="status">
            <el-radio-group v-model="form.status">
              <template v-for="(item, index) in radioData" :key="index">
                <el-radio :label="item.value">{{ item.label }}</el-radio>
              </template>
            </el-radio-group>
          </el-form-item>

          <!-- <el-form-item label="场地类型" :label-width="80" prop="fieldType">
            <el-radio-group v-model="form.fieldType">
              <el-radio label="WITHIN_VENUE">馆内</el-radio>
              <el-radio label="OUTSIDE_VENUE">馆外</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <!-- <el-form-item
            label="所属展会"
            :label-width="80"
            prop="eventId"
            v-if="form.fieldType == 'WITHIN_VENUE'"
          >
            <el-select v-model="form.eventId">
              <template v-for="(item, index) in eventList" :key="index">
                <el-option
                  :label="item.shortName"
                  :value="item.eventId"
                ></el-option>
              </template>
            </el-select>
          </el-form-item> -->

          <el-form-item label="活动简介" :label-width="80" prop="description">
            <vab-quill v-model="form.description" :min-height="400" :options="Richoptions" />
          </el-form-item>

          <el-form-item align="center">
            <el-button type="primary" @click="submitInfo()">
              保存活动基础信息
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card>
      <vab-query-form>
        <vab-query-form-left-panel :span="12">
          票务管理
        </vab-query-form-left-panel>
        <vab-query-form-right-panel :span="12">
          <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
            添加
          </el-button>
        </vab-query-form-right-panel>
      </vab-query-form>
      <el-table v-loading="listLoading" border :data="ticketList">
        <el-table-column prop="name" label="名称" />
        <el-table-column fixed label="开始时间" show-overflow-tooltip>
          <template #default="{ row }">
            <span>{{ handleDate(row.orderStartTime) }}</span>
          </template>
        </el-table-column>

        <el-table-column fixed label="结束时间" show-overflow-tooltip>
          <template #default="{ row }">
            <span>{{ handleDate(row.orderEndTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="是否展示">
          <template #default="{ row }">
            <el-tag v-if="row.isShow">展示</el-tag>
            <el-tag type="warning" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" />
        <el-table-column prop="type" label="类型">
          <template #default>
            <el-tag type="success">免费</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="explain" label="描述" />
        <el-table-column label="操作" show-overflow-tooltip width="185">
          <template #default="{ row }">
            <el-button type="primary" @click="handleEdit(row)">
              <vab-icon icon="edit-2-line" />
              编辑
            </el-button>
            <el-button type="danger" @click="handleDelete(row)">
              <vab-icon icon="delete-bin-6-line" />
              删除
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
    </el-card>
    <!-- <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="submitInfo()">提交</el-button>
    </div> -->

    <vab-upload ref="vabUploadRef" :limit="1" name="file" :size="2" url="/upload" @addImg="handleAddImg" />

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>

    <ticket-edit ref="ticketEditRef" @fetch-data="getTicket" :activeCode="form.code" :maxNum="form.peopleNum"></ticket-edit>
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'
import {
  upLoadImg,
  getLabelType,
  getLabelLabel,
  addActive,
  activeDetail,
  editActive,
  getActiveTicket,
  delActiveTicket,
} from '@/api/active'

import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'
import { getAllEvent } from '@/api/global'

import { parseTime } from '@/utils/index'
import VabQuill from '@/extra/VabQuill'
import VabUpload from '@/extra/VabUpload'
import { mapActions } from 'vuex'
export default defineComponent({
  name: 'editActive',
  components: {
    VabQuill,
    VabUpload,
    ticketEdit: defineAsyncComponent(() =>
      import('./components/activeTicketEdit')
    ),
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      dialogVisible: false,
      dialogImageUrl: '',
      formRef: null,
      ticketEditRef: null,
      vabUploadRef: null,
      checkMenu: [],
      ticketList: [],
      eventList: [],
      size: '50%',
      radioData: [
        {
          value: 'ON',
          label: '启用',
        },
        {
          value: 'OFF',
          label: '停用',
        },
        {
          value: 'DRAFT',
          label: '草稿',
        },
      ],
      form: {
        description: '',
        startTime: '',
        endTime: '',
        areaList: [],
        sort: 1,
      },
      rules: {
        title: [{ required: true, trigger: 'blur', message: '请输入活动标题' }],
        activeTime: [
          { required: true, trigger: 'blur', message: '请选择起止时间' },
        ],
        type: [
          { required: true, trigger: 'change', message: '请选择标签类型' },
        ],
        areaList: [
          { required: true, trigger: 'change', message: '请选择省市' },
        ],
        address: [
          { required: true, trigger: 'blur', message: '请填写详情地址' },
        ],
        bannerUrl: [
          { required: true, trigger: 'change', message: '请上传海报' },
        ],
        coverUrl: [
          { required: true, trigger: 'change', message: '请上传封面' },
        ],
        organisers: [
          { required: true, trigger: 'blur', message: '请输入主办方' },
        ],
        peopleNum: [
          { required: true, trigger: 'blur', message: '请输入活动人数' },
        ],
        sparkle: [
          { required: true, trigger: 'blur', message: '请输入活动亮点' },
        ],
        activityTagIds: [
          { required: true, trigger: 'blur', message: '请选择活动标签' },
        ],
        activityTypeCodes: [
          { required: true, trigger: 'blur', message: '请选择活动类型' },
        ],
        status: [
          { required: true, trigger: 'change', message: '请选择活动状态' },
        ],
        eventId: [
          { required: true, trigger: 'change', message: '请选择所属展会' },
        ],
        description: [
          { required: true, trigger: 'blur', message: '请输入活动简介' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      list: [],
      coverList: [],
      pickerStartDate: null,
      limit: 1,
      showCasc: false,
      Richoptions: {
        theme: 'snow',
        bounds: document.body,
        debug: 'warn',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ color: [] }, { background: [] }],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ align: [] }],
              [{ direction: 'rtl' }],
              [{ font: [] }],
              ['clean'],
              ['image'],
            ],
            handlers: {
              image: () => {
                state['vabUploadRef'].handleShow()
              },
            },
          },
        },
      },
      typeOption: [],
      tagOption: [],
    })

    const fetchData = async () => {
      const { data: typeList } = await getLabelType()
      const { data: tagList } = await getLabelLabel({}, 'ACTIVITY')
      state.typeOption = typeList
      state.tagOption = tagList
    }

    // 编辑活动获取活动详情
    const getActiveDetail = async (code) => {
      state.showCasc = false
      const { data } = await activeDetail({ code })
      data.activeTime = [parseTime(data.startTime), parseTime(data.endTime)]
      data.fileList = [{ name: 'bannar', url: data.bannerUrl }]
      data.coverList = [{ name: 'cover', url: data.coverUrl }]
      await fetchData()
      data.activityTypeCodes = handleData(data.activityTypeCodes)
      data.activityTagIds = handleData(data.activityTagIds)
      data.areaList = [data.province, data.city, data.area]
      console.log(data.status)
      state.form = Object.assign({}, data)
      state.showCasc = true
      await getTicket()
    }

    // 获取所有事件
    // const handleGetAllEvent = async () => {
    //   const { data } = await getAllEvent()
    //   state.eventList = data
    // }
    onMounted(() => {
      // handleGetAllEvent()
      console.log(`======?????${proxy.$route.query.code}`)
      state.activeCode = proxy.$route.query.code
      getActiveDetail(state.activeCode)
    })
    // 图片相关接口
    // 上传海报
    const upBannar = async (e) => {
      console.log(e)
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      console.log(data)
      state.form.bannerUrl = data
    }

    const handleError = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed = (files) => {
      proxy.$baseMessage(
        `当前限制选择 ${proxy.limit} 个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleRemove = () => {
      state.form.bannerUrl = ''
    }
    const handlePreview = (file) => {
      state.dialogImageUrl = file.url
      state.dialogVisible = true
    }

    // 上传海报
    const upCover = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      state.form.coverUrl = data
    }

    const handleError1 = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed1 = (files) => {
      proxy.$baseMessage(
        `当前限制选择 1个文件，本次选择了
            1
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleRemove1 = () => {
      state.form.bannerUrl = ''
    }
    const handlePreview1 = (file) => {
      state.dialogImageUrl = file.url
      state.dialogVisible = true
    }

    // 富文本上传图片
    const handleAddImg = async (e) => {
      state.form.description += `<img src="${e}" />`
    }
    const submitInfo = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let msg = ''
          console.log(state.form)
          state.form.startTime = state.form.activeTime[0]
          state.form.endTime = state.form.activeTime[1]
          state.form.province = state.form.areaList[0]
          state.form.city = state.form.areaList[1]
          state.form.area = state.form.areaList[2]
          let data = {}
          if (state.activeCode != 'new') {
            data = await editActive(state.form)
            msg = data.msg
          } else {
            data = await addActive(state.form)
            msg = data.msg
          }
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          console.log(data)
          proxy.$pub('reload-router-view')
        }
      })
    }

    const optionsLevel = {
      lazy: true,
      value: 'name',
      label: 'name',
      async lazyLoad(node, resolve) {
        const { level } = node
        const { code } = node.data
        if (level == 0) {
          const { data } = await getProvinceInfo()
          resolve(data)
        } else if (level == 1) {
          const { data } = await getCityInfo({ code: code })
          resolve(data)
        } else {
          const { data } = await getAreaInfo({ code: code })
          data.forEach((v, i) => {
            v.leaf = level >= 2
          })
          resolve(data)
        }
      },
    }

    // 点击新增票务
    const handleEdit = (row) => {
      if (state.form.id) {
        if (row.id) {
          state.ticketEditRef.showEdit(row)
        } else {
          state.ticketEditRef.showEdit()
        }
      } else {
        proxy.$message.warning('请先保存当前活动基础信息')
      }
    }

    // 获取票据信息
    const getTicket = async () => {
      if (state.form.code) {
        const { data } = await getActiveTicket({
          activityCode: state.form.code,
        })
        state.ticketList = data
      }
    }

    const handleDate = (val) => {
      return parseTime(val)
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await delActiveTicket({ id: row.id })
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await getTicket()
        })
      }
    }

    const handleData = (arr, checkArr) => {
      for (let i in arr) {
        arr[i] = String(arr[i])
      }
      return arr
    }

    return {
      ...toRefs(state),
      upBannar,
      fetchData,
      handleAddImg,
      submitInfo,
      handleError,
      handleExceed,
      handleRemove,
      upCover,
      handleError1,
      handleRemove1,
      handlePreview1,
      handleExceed1,
      optionsLevel,
      handlePreview,
      handleEdit,
      getTicket,
      handleDate,
      handleDelete,
      handleData,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

// .demo-drawer__content {
//   // height: 85vh;
//   // overflow: auto;
// }
.demo-drawer__footer {
  position: fixed;
  right: 20px;
  bottom: 10px;
}

.contentBox {
  height: 80vh;
  overflow: auto;
}
</style>
